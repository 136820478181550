import * as amplitude from '@amplitude/analytics-browser'
import type { ValidPropertyType } from '@amplitude/analytics-types'
import type { StripeAddressElementChangeEvent } from '@stripe/stripe-js'
import { getCookieABTests } from 'ab_testing'
import { AddressVerificationData } from 'components/AddressVerification'
import { AffirmLocations, BundleInteractionType, HomePageHeroCTAType, ProactiveInteractionType } from 'constants/events'
import { datadogReportAPICall, datadogReportEvent } from 'events/datadog'
import { sha256 } from 'js-sha256'
import Stripe from 'stripe'
import { Product } from 'utils/events'
import { formatLink, formatURL } from 'utils/formatters'

enum UserPropertyOperation {
	APPEND = 'append',
	NEW = 'new',
}
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_IS_PROD === 'true' ? 'b939591fd87a7f2d814ac84016c60699' : '3d58ab67e56af197929011a29b73a84f'

const SYSTEM_COOKIE_MAX_AGE = 7 * 24 * 60 * 60
const SYSTEM_AMPLITUDE_COOKIE_NAME = 'SYSTEM_AMP_DEV_ID'

const setAmplitudeCookie = (id?: string): void => {
	if (typeof window !== 'undefined') {
		const d = new Date(Date.now() + SYSTEM_COOKIE_MAX_AGE * 1000)
		const expires = 'expires=' + d.toUTCString()
		const domain = window?.location?.hostname ? `domain=.${window.location.hostname}` : ''
		const secure = process.env.NODE_ENV === 'production' ? 'secure' : ''
		document.cookie = `${SYSTEM_AMPLITUDE_COOKIE_NAME}=${id ?? ''};${expires};path=/;sameSite=lax;${domain};${secure}`
	}
}

const logEvent = (eventType: string, eventProperties: object | null = null, functionName: string | null = null): void => {
	setAmplitudeCookie(amplitude.getDeviceId())
	try {
		if (typeof window === 'undefined' && !window) {
			return
		}

		if (window.CONFIG_TYPE === 'DEVELOPMENT' || window.location.hostname.includes('chromatic.com')) {
			//eslint-disable-next-line no-console
			console.log('Amplitude not available in Storybook', eventType)
			return
		}

		if (sessionStorage.getItem('automated') === 'true' || window.location.search.includes('automated=true')) {
			//eslint-disable-next-line no-console
			console.log('Amplitude not available in automated testing', eventType)
			return
		}

		const tests = [...getCookieABTests().keys]
		const abTestsProp = {
			abTests: tests,
		}

		amplitude
			.track(eventType, { ...abTestsProp, ...eventProperties })
			.promise.then((res) => {
				if (functionName !== null && sessionStorage.getItem(functionName) !== null) {
					sessionStorage.removeItem(functionName)
				}
				datadogReportAPICall(res.code, eventType, eventProperties)
			})
			.catch(() => {
				datadogReportEvent('failure', eventType, eventProperties)
			})
		datadogReportEvent('success', eventType, eventProperties)
	} catch (err) {
		datadogReportEvent('failure', eventType, eventProperties)
	}
}

export const amplitudeThankYou = (invoice: Stripe.Invoice): void => {
	let totalProducts = 0
	invoice.lines.data.forEach((line) => {
		purchaseProduct(line, invoice.id)
		totalProducts += line.quantity ?? 0
	})
	revenue(invoice.total / 100, totalProducts, invoice.id)
	logEvent('Order Confirmation')
	logEvent('Checkout Complete')
	logEvent('Checkout - Completed Purchase', {
		orderId: invoice.id,
		totalOrderValue: invoice.total / 100,
		isPodOrder: false,
		isPodProOder: false,
		dateTime: new Date().toLocaleString('en-US', {
			timeZone: 'America/New_York',
		}),
	})
}

const purchaseProduct = (item: Stripe.InvoiceLineItem, transactionId: string): void => {
	logEvent('eCommerce - Purchase - Item', {
		Id: item.price?.metadata?.sku,
		Name: item.description?.replace(/[^ -~]+/g, ''),
		Price: item.amount / 100,
		Quantity: item.quantity ?? 0,
		'Total Product Amount': (item.amount * (item.quantity ?? 0)) / 100,
		'Transaction Id': transactionId,
	})
}

const revenue = (price: number, productCount: number, transactionId: string): void => {
	const revenue = new amplitude.Revenue()
	revenue.setPrice(price)
	revenue.setQuantity(1)
	revenue.setEventProperties({
		'Product Count': productCount,
		'Transaction Id': transactionId,
	})
	amplitude.revenue(revenue)
}

export const appendUserProperty = (property: string, value: ValidPropertyType | null = null, type: UserPropertyOperation = UserPropertyOperation.APPEND): void => {
	try {
		if (property !== null && value !== null && type === UserPropertyOperation.APPEND) {
			const data: Record<string, ValidPropertyType> = {}
			data[property] = value
			const identifyEvent = new amplitude.Identify().append(property, value)
			amplitude.identify(identifyEvent)
		}
		if (property !== null && value !== null && type === UserPropertyOperation.NEW) {
			const identifyEvent = new amplitude.Identify().set(property, value)

			amplitude.identify(identifyEvent)
		}
	} catch (err) {
		datadogReportEvent('failure', 'appendUserProperty', {
			property,
			value,
			type,
		})
	}
}

export const trackHasVisitedSite = (): void => {
	appendUserProperty('hasVisitedWebsite', true, UserPropertyOperation.NEW)
}

export const amABTests = (): void => {
	const savedTests = localStorage.getItem('ab-tests')?.split(',') || []
	const newTests: string[] = []

	try {
		const otherTests = [...getCookieABTests().keys]
		for (const test of otherTests) {
			const key = test.split('_')[0]
			const value = test.split('_')[1]
			if (savedTests.indexOf(key) === -1) {
				newTests.push(`${key}_${value}`)
				savedTests.push(key)
			}
		}

		localStorage.setItem('ab-tests', savedTests.toString())
		appendUserProperty('ab-tests', newTests, UserPropertyOperation.APPEND)
	} catch (err) {
		datadogReportEvent('failure', 'amABTests', {
			err,
		})
	}
}

// Rajiv Checked
export function amPageView(): void {
	try {
		logEvent('Viewed Page View', {
			URL: formatURL(window.location.href),
		})
	} catch (e) {
		datadogReportEvent('failure', 'amPageView', {
			e,
		})
	}
}

// currently not in use
// Rajiv checked
export function amRemoveFromCart(name: string, sku: string, price: number, quantity = 1): void {
	logEvent('Remove from Cart', {
		name,
		sku,
		price,
		quantity,
		URL: formatURL(window.location.href),
	})
}

// not in use
export function amCtaClick(button_name: string): void {
	logEvent('Click - Info - The Pod', { 'Button Name': button_name })
}

// Rajiv checked and validated
export function amClickBeds(bedType: string): void {
	logEvent('Shop - Beds', {
		'Source Page URL': formatURL(window.location.href),
		'Bed Type': bedType,
	})
}

export function amClickDropdownAccessory(name: string, location: string, badge: string): void {
	logEvent('Click - Dropdown - Accessories', {
		'Source Page URL': formatLink(formatURL(window.location.href)),
		name,
		location,
		badge,
	})
}

// Rajiv checked and validated
export function amClickAccessories(type: string): void {
	logEvent('Shop - Accessories', {
		'Source Page URL': formatURL(window.location.href),
		'Accessory Type': type,
	})
}

// Rajiv checked and validated
export function amClickNav(target: string): void {
	logEvent('Click - Navigation - Top', {
		'Source Page URL': formatURL(window.location.href),
		Destination: target,
	})
}

// Rajiv checked and validated footer and checkout
export function amSubscribeEmail(email: string, location: string): void {
	logEvent('Subscribe', { Email: email, 'Subscribe Location': location })
}

export function amSubscribeReferralEmail(email: string, location: string, referralCode: string): void {
	logEvent('Subscribe', { Email: email, 'Subscribe Location': location, 'Referred By': referralCode, referral_modal: 'true' })
}

// not in use
// Rajiv checked and validated
export function amAppDownload(os: string, page = 'pod_landing_page'): void {
	logEvent('App Download', { 'App Store': os, 'Page Position': page })
}
// Rajiv checked and validated
export function amClickFaq(question: string): void {
	logEvent('Click - FAQ - The Pod', { Question: question })
}

// Rajiv checked and validated
export const amClickSocialMedia = (target: string): void => {
	logEvent('Click - Social Media External', {
		'Source Page URL': window.location.href,
		Destination: target,
	})
}

// Rajiv checked and validated
export const amClickSupport = (target: string): void => {
	logEvent(`Click - Support - ${target}`, {
		'Source Page URL': window.location.href,
	})
}

// Rajiv checked and validated
export const amClickFooter = (target: string): void => {
	logEvent('Click - Footer Button - General', {
		'Source Page URL': window.location.href,
		Destination: target,
	})
}

// TODO: rename the below events

// Rajiv checked and validated
export const amOpenCart = (): void => {
	logEvent('Open or Close - Shopping Cart', {
		'Source Page URL': formatURL(window.location.href),
		Action: 'open',
	})
}

// Rajiv checked and validated
export const amCloseCart = (): void => {
	logEvent('Open or Close - Shopping Cart', {
		'Source Page URL': formatURL(window.location.href),
		Action: 'close',
	})
}

// Rajiv checked and validated
export const amClickUseCase = (target: string): void => {
	logEvent(`Click - Use Case - ${target}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// Rajiv checked and validated
export const amViewToolTip = (target: string): void => {
	logEvent(`View ToolTip`, {
		'Source Page URL': formatLink(formatURL(window.location.href)),
		'Tool Tip': target,
	})
}

// Rajiv checked and added to new twitter slider on podlanding; validated
export const amClickTwitterSlider = (target: string): void => {
	logEvent(`Click Twitter Slider -  ${target}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

export const amClickCheckoutPayment = (target: string): void => {
	logEvent(`Initiated Payment - ${target}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// Not in use
// Rajiv has checked
export const amViewTypography = (): void => {
	logEvent(`View Typography`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// not in use
// Rajiv has checked
export const amClickUrgencyArea = (): void => {
	logEvent(`Click Urgency 'X In Your Area'`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// on who-we-are
// this currently works any time bio is clicked (expanded or closed)
// Rajiv has checked and validated
export const amClickBioCard = (name: string): void => {
	logEvent('Expand Scientist Card', {
		'Scientist Name': name,
		'Source Page URL': window.location.href,
	})
}

// not currently in use
// Rajiv has checked
export const amFirstLandingPageVisit = (): void => {
	logEvent('First Landing Page Visit', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

// not in use
// rajiv has checked
// export const handleFirstPageVisit = () => {
// 	try {
// 		window.amplitude.getInstance().onInit()(() => {
// 			setTimeout(() => {
// 				amFirstLandingPageVisit()
// 			}, 2000)
// 		})
// 	} catch (e) {
// 		console.log(e)
// 	}
// }

// not in use
// Rajiv has checked
export const amShowUrgencyTest = (dates: string[], zipcode: string, qi = null, ki = null, cki = null): void => {
	logEvent(`Show Urgency Dates`, {
		Dates: dates,
		Zipcode: zipcode,
		'Queen Invetory': qi,
		'King Inventory': ki,
		'Cali King Inventory': cki,
		'Source Page URL': formatURL(window.location.href),
	})
}

// click to get details for nearby store modal
// not in use
// Rajiv has checked
export const amClickNearbyStoreModalDetails = (store: string): void => {
	logEvent(`Click Nearby Store Modal - ${store}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// clicked map on nearby store modal
// not in use
// Rajiv has checked
export const amClickNearbyStoreModalMap = (store: string): void => {
	logEvent(`Click Nearby Store Modal Map - ${store}`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

// complete contact information
// Rajiv has checked and validated
export const amCompleteContactInformation = (): void => {
	logEvent(`Checkout - Complete Contact Info`)
}

// Rajiv has checked and validated
export const amCompletePaymentMethodSelection = (paymentMethod: string, itemNames: string[]): void => {
	// we do this to not break tracking w/ GA. Eventually we should GA to use the 'pay now - ...' convention
	const formattedPaymentName = paymentMethod === 'recharge' ? 'pay now - recharge' : paymentMethod
	logEvent(`Checkout - Completed Payment Method`, {
		paymentMethod: formattedPaymentName,
		itemNames,
	})
}

// Rajiv has checked and validated
export const amInitiateCheckout = (itemNames: string[]): void => {
	logEvent(`Checkout - Initiated`, {
		'Source Page URL': formatURL(window.location.href),
		itemNames,
	})
}

// TODO: rajiv validate
export const amAddEmailUserProperty = (email: string): void => {
	appendUserProperty('email', email, UserPropertyOperation.NEW)
}

// Rajiv has checked and validated
export const amEnterEmailInCheckout = (email: string): void => {
	logEvent('Checkout - Enter Email', { email })
}

export const amEnterShippingAddressInCheckout = (address: StripeAddressElementChangeEvent['value']): void => {
	logEvent('Checkout - Enter Shipping Address', { address })
}

// Rajiv has checked and validated
export const amAddToCartV1 = (product: Product, saveToSessionStorage: boolean): void => {
	logEvent('Add to Cart', product)
	// this is the legacy event we can use for historical comparison

	if (saveToSessionStorage) sessionStorage.setItem('amAddToCartV1', JSON.stringify(product))
	logEvent('eCommerce - AddToCart - Item', product, 'amAddToCartV1')
}

export const removeFromCart = (product: Product): void => {
	logEvent('Remove from Cart', product)
}

export const updateItemInCart = (product: Product, action: string): void => {
	logEvent(`${action} item quantity in cart`, product)
}

// Rajiv has checked and validated
export const amClickAffirmLearnMore = (location: AffirmLocations): void => {
	logEvent('Clicked Affirm Learn more', { location })
}

// Rajiv has checked and validated
export const amViewedPageSet = (eventName: string, pageType: string): void => {
	logEvent(eventName, { pageType })
}

// not in use
// Rajiv has checked and validated
export const amClickedShopReviewStars = (): void => {
	logEvent('Click Review Stars', {
		location: formatLink(formatURL(window.location.href)),
	})
}

export const amPageLoaded = (): void => {
	const loadTime = window.performance.timing.loadEventEnd - window.performance.timing.navigationStart
	logEvent('Page Loaded', {
		location: formatLink(formatURL(window.location.href)),
		'Source Page URL': window.location.href,
		'Load Time': loadTime,
	})
}

// Rajiv has checked and validated
export const amInteractedPaidAccessoryDescription = (accessory: string, actionType: string): void => {
	// actionType is Expanded or Closed
	logEvent('Upsell Accessory - Description ' + actionType, {
		accessory,
		location: formatLink(formatURL(window.location.href)),
	})
}

// not in use
// Rajiv has checked
export const amInteractedFreeAccessoryDescription = (accessory: string, actionType: string): void => {
	logEvent('Free Accessory - Description ' + actionType, {
		accessory,
	})
}

// not in use
// Rajiv has checked
export const amToggledFreeAccessory = (accessory: string): void => {
	logEvent('Free Accessory - Toggled', {
		accessory,
	})
}

// not in use
// Rajiv has checked
export const amSelectedFreeAccessory = (accessory: string): void => {
	logEvent('Free Accessory - Selected', {
		accessory,
	})
}

// Rajiv has checked and validated
export const amClosedPromoBar = (): void => {
	logEvent('Promo Bar - Closed', {
		location: formatLink(formatURL(window.location.href)),
	})
}

// not in use??
// Rajiv has checked
export const amWatchedVideo = (): void => {
	logEvent('Video - Viewed', {
		location: formatLink(formatURL(window.location.href)),
	})
}

// not in use
// Rajiv has checked
export const amClickedFeatureBlock = (feature: string): void => {
	logEvent('Clicked Feature Block', {
		feature,
	})
}

// not in use
// Rajiv has checked
export const amClickHomePageHeroCTA = (cta: HomePageHeroCTAType): void => {
	logEvent('Click Home Page Hero CTA', { cta })
}

// no longer in use
// Rajiv has checked
export const amClickChevronSubNav = (action: string): void => {
	logEvent('Click Subnav Chevron', { action })
}

// not in use
// Rajiv has checked
export const amOpenFreeAccessoryModal = (accessory: string): void => {
	logEvent('Open Free Accessory Modal', {
		accessory,
		location: formatLink(formatURL(window.location.href)),
	})
}

// Rajiv has checked and validated
export const amClickedSelectedPodProduct = (variant: string, id: number): void => {
	logEvent('Clicked Pod Product', {
		variant,
		id,
	})
}

// Rajiv has checked and validated
export const amClickedChat = (): void => {
	logEvent('Clicked Chat', {
		URL: formatURL(window.location.href),
	})
}

// Rajiv has checked and validated
export const amViewedPodSpecs = (): void => {
	logEvent('View Specs - The Pod')
}

// Rajiv has checked and validated
export const amClickLink = (to: string, htmlId: string): void => {
	logEvent('Click Button', {
		'Source Page URL': window.location.href,
		target: to,
		'HTML element id': htmlId,
	})
}

export const setUserIdAsHashedEmail = (email: string): void => {
	const hashedEmail = sha256(email)
	try {
		if (amplitude.getUserId() === undefined) {
			amplitude.setUserId(hashedEmail)
		}
	} catch (e) {
		datadogReportEvent('failure', 'setUserIdAsHashedEmail', {
			e,
		})
	}
}

export const amClickCallUsCard = (option: string): void => {
	logEvent('Clicked Call Us Card', {
		URL: formatLink(window.location.href),
		option,
	})
}

export const amClickedStudy = (study: string): void => {
	logEvent('Clicked Study', {
		URL: formatLink(formatURL(window.location.href)),
		study,
	})
}

export const amCompareSliderInteraction = (): void => {
	logEvent('Interacted with Compare Slider')
}

export const amTechSliderInteraction = (title: string): void => {
	logEvent('Tech Slider Interaction', { title })
}

export const amClickedUpsellSlider = (sliderName: string): void => {
	logEvent('Clicked Upsell Slider', { sliderName })
}

export const amTrackBundleInteraction = (interaction: BundleInteractionType, name: string): void => {
	logEvent(interaction + ' Bundle', { name })
}

export const amTrackAccesoryCardV2ButtonClick = (to: string): void => {
	try {
		logEvent('Clicked Accessory Card Button', {
			from: formatLink(formatURL(window.location.href)),
			to,
		})
	} catch (e) {
		datadogReportEvent('failure', 'amTrackAccesoryCardV2ButtonClick', {
			e,
		})
	}
}

export const amTrackProactiveChatInteraction = (interactionType: ProactiveInteractionType): void => {
	logEvent(interactionType + ' Proactive Chat', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedShopSectionOnVanity = (name: string): void => {
	logEvent('Viewed shop section on T1/T2', {
		name: name,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedShopPage2xIn90Days = (daysInBetweenVisits: number): void => {
	logEvent('Viewed Pod Shop Page 2x in 90 days', { daysInBetweenVisits })
}

export const amViewedShopPage2xIn28Days = (daysInBetweenVisits: number): void => {
	logEvent('Viewed Pod Shop Page 2x in 28 days', { daysInBetweenVisits })
}

export const amClickedGridFilter = (filter: string): void => {
	logEvent('Clicked Grid Filter', {
		URL: formatLink(window.location.href),
		filter,
	})
}

export const amTrackClickedVideoOnReviewCard = (title: string, id: string): void => {
	logEvent('Watched Review Card Video', {
		title,
		id,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTrackClickedCoverExplainerVideoPlay = (): void => {
	logEvent('Watched Cover Explainer video', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

// index in list of mattress bundles
export const amAddMattressBundleToCart = (title: string, ids: number[], isPodPro: boolean, size: string, index: number): void => {
	logEvent('Peloton Bundle - Added Mattress Bundle To cart', {
		title,
		ids,
		isPodPro,
		size,
		index,
	})
}

export const amTrackMattressBundleSlideDown = (title: string, ids: number[], isPodPro: boolean, size: string, index: number): void => {
	logEvent('Peloton Bundle - Expanded Mattress Bundle Includes List', {
		title,
		ids,
		isPodPro,
		size,
		index,
	})
}

export const amAddAppleStyleUpsellToCart = (title: string, productId: number, variantId: number): void => {
	logEvent('Apple Bundle - Add Upsell To Cart', {
		title,
		productId,
		variantId,
	})
}

export const amClickShopTab = (title: string): void => {
	logEvent('Clicked Tab - Shop Page', {
		title,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEmailCapClickBookDemo = (properties: Record<string, string>): void => {
	logEvent('Email Cap - Click Book Demo', { ...properties })
}

export const amEmailCapQuizChoice = (choice: string, modalName: string): void => {
	logEvent('Quiz Email Capture - ' + choice, { Type: modalName })
}

export const amViewedEmailCap = (modalName: string): void => {
	logEvent('Email Cap Modal Shown', {
		Modal: modalName,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedAssistedModal = (modalName: string): void => {
	logEvent('Assisted Sale Modal Shown', {
		Modal: modalName,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedSecondaryEmailCapModal = (modalName: string): void => {
	logEvent('Secondary Email Cap Modal Shown', {
		Modal: modalName,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEmailButtonModalPress = (modalName: string, buttonPressed: string): void => {
	logEvent(`Clicked ${buttonPressed}`, {
		Modal: modalName,
	})
}

export const amTrackEmailModalFloatingButtonPress = (): void => {
	logEvent(`Clicked ecap floating button`, {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTrackEmailModalDismissFloatingButtonPress = (): void => {
	logEvent(`Dismiss ecap floating button`, {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscribedViaEmailCap = (properties: Record<string, string | boolean | number>): void => {
	logEvent('BZ - Email Capture', {
		...properties,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEcapQuizShown = (): void => {
	logEvent('Ecap Quiz Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSecondaryQuizEcapShown = (): void => {
	logEvent('Secondary Special Quiz Ecap Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSecondaryQuizEcapStepShown = (step: number): void => {
	logEvent(`Secondary Special Quiz Ecap Step ${step} shown`, {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEcapQuizAnswered = (answer: string, question: string): void => {
	logEvent('Clicked Ecap Quiz Question', {
		Answer: answer,
		Question: question,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSecondaryQuizEcapAnswered = (answer: string, question: string): void => {
	logEvent('Clicked Secondary Quiz Ecap Question', {
		Answer: answer,
		Question: question,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSelectReasonsNotYes = (action: string, reason: string, index: number): void => {
	logEvent(`${action} - ${reason}`, { index })
}

export const amCompleteFollowUpQuestionEmailCap = (question: string, reasons: string[] | string, modalName: string): void => {
	logEvent('Completed Follow Up Question', { question, reasons, modalName })
}

export const amOptInSMS = (choice: boolean): void => {
	logEvent(`Opt ${choice ? 'in' : 'out'} SMS`)
	appendUserProperty('opt_in_sms', choice, UserPropertyOperation.NEW)
}

export const amClickTonePolicyLink = (): void => {
	logEvent('Tone - Clicked Policy Link')
}

export const amClickFinancingModalAffirm = (location: string): void => {
	logEvent('Clicked Learn more - Affirm', {
		URL: location ? location : formatLink(formatURL(window.location.href)),
	})
}

export const amClickAssistedSaleCall = (location: string): void => {
	logEvent('Assisted Sales - Clicked Call', {
		URL: formatLink(formatURL(window.location.href)),
		location: location,
	})
}

export const amClickAccessoryVideoLink = (item: string, type: string): void => {
	logEvent('Clicked Accessory Video Link', {
		item,
		type,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickBlueAccessoryCard = (item: string): void => {
	logEvent('Clicked Blue Accessory Card', {
		item,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickFinancingBannerButton = (item: string): void => {
	logEvent('Clicked Financing Banner Button', {
		item,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickAssistedSaleChat = (location = null): void => {
	logEvent('Assisted Sales - Clicked Chat', {
		URL: formatLink(formatURL(window.location.href)),
		location: location,
	})
}

export const amClickAssistedSaleDemo = (location: string): void => {
	logEvent('Assisted Sales - Clicked Book Demo', {
		URL: formatLink(formatURL(window.location.href)),
		location: location,
	})
}

export const amInteractWithCartUpsellLearnMore = (item: string): void => {
	logEvent('Cart Upsell Interaction - Learn more', {
		URL: formatLink(formatURL(window.location.href)),
		item,
	})
}

export const amClickedContactUsItem = (contactMethod: string): void => {
	logEvent('Clicked Contact Item', {
		URL: formatLink(formatURL(window.location.href)),
		contactMethod,
	})
}
export const amClickSleepFitnessProductCard = (item: string): void => {
	logEvent('Clicked Sleep Fitness Product Card', {
		item,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedVideoInComfortSlider = (name: string, id: string): void => {
	logEvent('Comfort Slider - Viewed Video', {
		name,
		id,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amViewedAllVideosInComfortSlider = (): void => {
	logEvent('Comfort Slider - Viewed All Videos', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSelectedMattressComfortLayers = (name: string): void => {
	logEvent('Comfort Layers - Clicked Mattress', {
		name,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickedComfortLayer = (name: string, videoTitle: string, arrow: string): void => {
	logEvent('Comfort Layers - Clicked Layer', {
		name,
		videoTitle,
		arrow,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amInteractedWithAllComfortLayersForMattress = (name: string): void => {
	logEvent('Comfort Layers - Interacted With All Layers', {
		name,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amInteractedWithAllMattressesComfortLayers = (): void => {
	logEvent('Comfort Layers - Interacted With All Mattresses', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSwitchProduct = (name: string): void => {
	logEvent('Switched Product', {
		name,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickAwardWinningPressLink = (source: string): void => {
	logEvent('Clicked Award Winning Press Link', {
		source,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export type NavLinkType =
	| 'Main'
	| 'Desktop Pods Dropdown'
	| 'Mobile Pods Dropdown'
	| 'Desktop Pods Dropdown Image'
	| 'Desktop Accessories Dropdown Image'
	| 'Desktop Accessories Dropdown'
	| 'Mobile Accessories Dropdown'
	| 'Mobile Subnav'
	| 'Desktop Nav Dropdown'

export const amClickNavLink = (type: NavLinkType, target: string): void => {
	logEvent('Click Nav Link', {
		'Source Page URL': formatURL(window.location.href),
		type: type,
		name: target,
	})
}

export const clickedPlusMembership = (location = 'na'): void => {
	logEvent('Clicked 8+ Membership Modal', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const clickedTooltip = (location = 'na'): void => {
	logEvent('Clicked Tooltip', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const clickedTwitterCTA = (location = 'na', href = 'na'): void => {
	logEvent('Clicked Twitter Wall CTA', {
		href: href,
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectMembershipPlan = (plan: string): void => {
	logEvent('Select membership in modal', {
		membership: plan,
		location: 'membership change modal',
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackCartMembershipUpdate = (plan: string): void => {
	logEvent('Update cart membership', {
		membership: plan,
		location: 'cart',
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectBasicPlan = (location: string): void => {
	logEvent('8PLUS - Select Basic Plan', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectProPlan = (location: string): void => {
	logEvent('8PLUS - Select Pro Plan', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectProPlanAnnual = (location: string): void => {
	logEvent('8PLUS - Select Pro Plan Annual', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackSelectElitePlan = (location: string): void => {
	logEvent('8PLUS - Select Elite Plan', {
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackPlanHoverInfo = (tooltip: string, plan: 'basic' | 'pro' | 'standard', location: string): void => {
	logEvent('8PLUS - View Plan Tooltip', {
		plan: plan,
		tooltip: tooltip,
		location: location,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const trackPlanFeatureHoverInfo = (tooltip: string): void => {
	logEvent('8PLUS - View Plan Feature Tooltip', {
		tooltip: tooltip,
		location: 'select-plan',
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCheckoutCanadaWarning = (itemNames: string[]): void => {
	logEvent(`Checkout - Canada Warning`, {
		'Source Page URL': formatURL(window.location.href),
		itemNames,
	})
}

export const amClickPricingSectionAffirm = (target: string): void => {
	logEvent('Shop Page - Price Toggle', {
		'Source Page URL': formatURL(window.location.href),
		'Finance Type': target,
	})
}
export const amClickHamburgerABC = (status: 'Open' | 'Close'): void => {
	logEvent('Hamburger Menu - Open or Close', {
		'Source Page URL': formatURL(window.location.href),
		Action: status,
	})
}

export const amHamburgerABCLink = (url: string): void => {
	logEvent('Hamburger Menu - Select Link', {
		'Source Page URL': formatURL(window.location.href),
		Destination: url,
	})
}

export const amHamburgerABCDropdown = (title: string): void => {
	logEvent('Hamburger Menu - Open Submenu', {
		'Source Page URL': formatURL(window.location.href),
		Submenu: title,
	})
}

export const amClickedLeasePriceBlock = (status: 'Full Price' | 'Lease'): void => {
	logEvent('Clicked Lease Pricing Block', {
		'Source Page URL': formatURL(window.location.href),
		Action: status,
	})
}

export const amClicked8PlusModal = (): void => {
	logEvent('Clicked 8+ Modal', {
		'Source Page URL': formatURL(window.location.href),
		Action: 'Clicked 8+ Modal',
	})
}

export const amClickedCoverDualZone = (title: string): void => {
	logEvent('Clicked Cover DualZone', {
		'Source Page URL': formatURL(window.location.href),
		Title: title,
	})
}

export const amClickedSleepCoachingEmailCap = (): void => {
	logEvent('Clicked Sleep Coach Modal CTA', {
		'Source Page URL': formatURL(window.location.href),
	})
}

export const amClickedAccordionPanel = (title: string): void => {
	logEvent(`Open drawer "${title}"`, {
		'Source Page URL': formatURL(window.location.href),
	})
}

export const amClickedButton = (name: string, htmlId: string, trackingData?: Record<string, string>): void => {
	logEvent('Click Button', {
		'Name (Button copy)': name,
		'HTML element id': htmlId,
		'Source Page URL': formatURL(window.location.href),
		...trackingData,
	})
}

export const amClickedFAQ = (question: string): void => {
	logEvent('Click Button', {
		Question: question,
		'Source Page URL': formatURL(window.location.href),
	})
}

export const amViewedSleepCoachingEmailCap = (): void => {
	logEvent('Sleep Coaching Intro Modal Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amAIPoweredInsightsModal = (title: string, htmlId: string): void => {
	logEvent('Click Button', {
		'Source Page URL': formatURL(window.location.href),
		'HTML element id': htmlId,
		Title: title,
		Action: "Clicked 'learn more'",
	})
}

// Rajiv checked and validated
export const amClickSelectPlanFAQ = (q: string): void => {
	logEvent(`Expanded FAQ`, {
		'Source Page URL': formatURL(window.location.href),
		Title: 'Expanded FAQ',
		Action: q,
	})
}

export const amExpandedShopPageFeatureCard = (feature: string): void => {
	logEvent('Expanded feature card', {
		'Source Page URL': formatURL(window.location.href),
		Title: feature,
	})
}

export const amUpdateUserUTMs = (utm_medium: string, utm_source: string): void => {
	const identify = new amplitude.Identify()
	identify.set('utm_medium', utm_medium).set('utm_source', utm_source)
	amplitude.identify(identify)
}

export const amSubscribedViaSMSCap = (properties: Record<string, string>): void => {
	logEvent('BZ - SMS Capture', {
		...properties,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCheckIncludeMembership = (checked: boolean): void => {
	logEvent('Variant check add 8+ Membership', {
		checked,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTonalCheckIncludeMembership = (checked: boolean): void => {
	logEvent('Tonal Variant check add 8+ Membership', {
		checked: checked,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amOpenMembershipModal = (): void => {
	logEvent('Tonal Variant open 8+ Membership modal', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCloseMembershipModal = (): void => {
	logEvent('Tonal Variant close 8+ Membership modal', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickMembershipModalDrawer = (id: string): void => {
	logEvent('Click Membership Modal Drawer', {
		id: id,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amClickMembershipInModal = (membership: string): void => {
	logEvent('Click Membership In Modal', {
		membership: membership,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSelectedVariant = (variant: string): void => {
	logEvent('Selected Variant', {
		variant,
	})
}

export const amSelectedProduct = (productName: string, productId: number | string): void => {
	logEvent('Selected Product', {
		productName,
		productId,
	})
}

export const amSelectedMembership = (productName: string, productId: number | string): void => {
	logEvent('Selected Membership', {
		productName,
		productId,
	})
}

export const amSelectedBundleUpsell = (productName: string, productId: number | string): void => {
	logEvent('Selected Bundle Upsell', {
		productName,
		productId,
	})
}

export const amSelectedMattress = (productName: string, productId: number | string): void => {
	logEvent('Selected Mattress', {
		productName,
		productId,
	})
}

export const amSelectDropdown = (dropdownValue: string): void => {
	logEvent('Selected Dropdown', {
		dropdownValue,
	})
}
export const amChangePlanCheckbox = (plan: string): void => {
	logEvent('Plan checkbox selected', {
		Title: 'Switched plan',
		Action: 'Clicked plan: ' + plan,
		'Source Page URL': formatURL(window.location.href),
	})
}

export const amOpenSPFCompare = (): void => {
	logEvent('Open SPF Compare', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCloseSPFCompare = (): void => {
	logEvent('Close SPF Compare', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amOpenSPFFeatures = (): void => {
	logEvent('Open SPF Features', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCloseSPFFeatures = (): void => {
	logEvent('Close SPF Features', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amShowStickyATC = (): void => {
	logEvent('Sticky ATC Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amHideStickyATC = (): void => {
	logEvent('Sticky ATC Hid', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amShowedTooltip = (id: string): void => {
	logEvent('Showed Tooltip', {
		URL: formatLink(formatURL(window.location.href)),
		product: id,
	})
}

export const amHidTooltip = (id: string): void => {
	logEvent('Hid Tooltip', {
		URL: formatLink(formatURL(window.location.href)),
		product: id,
	})
}

export const amClickedTooltipButton = (id: string, minus: boolean): void => {
	logEvent('Clicked Tooltip Button', {
		URL: formatLink(formatURL(window.location.href)),
		id: id,
		minus: minus.toString(),
	})
}

export const amClickMarker = (location: string): void => {
	logEvent('Click Map Marker', {
		URL: formatLink(formatURL(window.location.href)),
		location: location,
	})
}

export const amScrollMarker = (percentage: number): void => {
	logEvent('Hit Scroll Marker', {
		URL: formatLink(formatURL(window.location.href)),
		percentage: percentage,
	})
}

export const amExpandedFooterSection = (header: string): void => {
	logEvent('Expanded Footer Section', {
		URL: formatLink(formatURL(window.location.href)),
		header,
	})
}

export const amClosedFooterSection = (header: string): void => {
	logEvent('Expanded Footer Section', {
		URL: formatLink(formatURL(window.location.href)),
		header,
	})
}

export const amOpenSelectPlanModal = (): void => {
	logEvent('Open Select Plan Cart Modal', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCloseSelectPlanModal = (): void => {
	logEvent('Close Select Plan Cart Modal', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amAppError = (message: string): void => {
	logEvent('Generic website error', {
		URL: formatLink(formatURL(window.location.href)),
		message: message,
	})
}

export const amAppCheckoutError = (message: string): void => {
	logEvent('Checkout failed', {
		URL: formatLink(formatURL(window.location.href)),
		message: message,
	})
}

export const amEmailCapShown = (): void => {
	try {
		const properties = {
			emailCapType: `Blog`,
			location: 'React Modal',
			captureLocation: formatURL(window.location.href),
		}
		logEvent('Blog - Email cap display', properties)
	} catch (e) {
		datadogReportEvent('failure', 'amEmailCapShown', {
			e,
		})
	}
}

export const amSelectPaymentMethod = (method: string): void => {
	logEvent('Checkout - Payment Select', {
		URL: formatLink(formatURL(window.location.href)),
		method: method,
	})
}

export const amInitiatedPayment = (method: string): void => {
	logEvent('Checkout - Payment Initiated', {
		URL: formatLink(formatURL(window.location.href)),
		method: method,
	})
}

export const amCompletePaymentMethod = (method: string): void => {
	logEvent('Checkout - Payment Complete', {
		URL: formatLink(formatURL(window.location.href)),
		method: method,
	})
}

export const amCheckoutError = (error: string, errorDetails: unknown, customerID: string, data: unknown): void => {
	logEvent('Checkout - Error', {
		URL: formatLink(formatURL(window.location.href)),
		error,
		errorDetails,
		customerID,
		data,
	})
}

export const amEnterPromoCode = (code: string): void => {
	logEvent('Checkout - Enter Promo Code', {
		URL: formatLink(formatURL(window.location.href)),
		code,
	})
}

export const amExitIntentModalShown = (): void => {
	logEvent('Exit Intent Modal Shown', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amExitIntentEmailCapture = (email: string): void => {
	logEvent('Exit Intent Email Capture', {
		URL: formatLink(formatURL(window.location.href)),
		email,
	})
}

export const amExitIntentSMSCapture = (sms: string): void => {
	logEvent('Exit Intent SMS Capture', {
		URL: formatLink(formatURL(window.location.href)),
		sms,
	})
}

export const amViewedStory = (story: number): void => {
	logEvent('Viewed Story', {
		URL: formatLink(formatURL(window.location.href)),
		story,
	})
}

export const amGoToMemberWebviewCheckout = (): void => {
	logEvent('Go to member webview checkout', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCompleteWebviewPurchase = (orderID: string): void => {
	logEvent('Complete webview purchase', {
		URL: formatLink(formatURL(window.location.href)),
		orderID,
	})
}

export const amShowSubscriptionPortalLogin = (data: { error: string; responseStatus?: number; responseData?: unknown; errorMessage?: string; search?: string }): void => {
	logEvent('Subscription portal - login show', {
		URL: formatLink(formatURL(window.location.href)),
		...data,
	})
}

export const amShowSubscriptionPortalLoginClick = (): void => {
	logEvent('Subscription portal - login click', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscriptionPortalLoginSuccess = (): void => {
	logEvent('Subscription portal - login successful', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscriptionPortalRedirectPaymentMethod = (email: string): void => {
	logEvent('Subscription portal - redirect to payment method', {
		URL: formatLink(formatURL(window.location.href)),
		email,
	})
}

export const amSubscriptionPortalDataLoaded = (email: string): void => {
	logEvent('Subscription portal - data loaded', {
		URL: formatLink(formatURL(window.location.href)),
		email,
	})
}

export const amSubscriptionPortalPaymentMethodsDataLoaded = (): void => {
	logEvent('Subscription portal - payment methods loaded', {
		email: sessionStorage.getItem('customerEmail'),
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscriptionPortalAutopilotCancelled = (plan: string): void => {
	logEvent('Subscription portal - autopilot cancelled', {
		email: sessionStorage.getItem('customerEmail'),
		URL: formatLink(formatURL(window.location.href)),
		plan,
	})
}

export const amSubscriptionPortalAutopilotPlanClicked = (plan: string): void => {
	logEvent('Subscription portal - autopilot plan selected', {
		email: sessionStorage.getItem('customerEmail'),
		URL: formatLink(formatURL(window.location.href)),
		plan,
	})
}

export const amSubscriptionPortalPaymentMethodDefault = (isDefaultCard: boolean): void => {
	logEvent(`Subscription portal - payment method set default: ${isDefaultCard.toString()}`, {
		email: sessionStorage.getItem('customerEmail'),
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSubscriptionPortalSubmitCardInfo = (email: string): void => {
	logEvent('Subscription portal - added new credit card', {
		email: email,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amEcapSubmitError = (error: string): void => {
	logEvent('Email Cap - submit error', {
		URL: formatLink(formatURL(window.location.href)),
		error,
	})
}

export const amSwipeImageCarousel = (direction: string, id: string): void => {
	logEvent('Swipe Image Carousel', {
		direction,
		id,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amChangeSlideImageCarousel = (id: string, index: number): void => {
	logEvent('Carousel change slide', {
		id,
		slide: index,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amSeenProductBreakdown = (): void => {
	logEvent('Product Breakdown - Seen', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTrackBaseSelected = (): void => {
	logEvent('Base upgrade selected', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amTrackBaseAddedToCheckout = (): void => {
	logEvent('Base upgrade added to checkout', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amShowAddressValidationModal = (data: AddressVerificationData): void => {
	logEvent('Show Address Validation Modal', {
		URL: formatLink(formatURL(window.location.href)),
		verdict: data.verdict.action,
		data,
	})
}

export const amCheckoutWithFragile = ({ size, currentPromoCode }: { size: string; currentPromoCode: string }): void => {
	logEvent('Checkout with fragile', {
		URL: formatLink(formatURL(window.location.href)),
		size,
		currentPromoCode,
	})
}

export const amShownRentalExperience = (): void => {
	logEvent('Shown Rental Experience', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amOpenSleepQuizModal = () => {
	logEvent('Open Sleep Quiz Modal', {
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amAnswerSleepQuizQuestion = ({ question, answer }: { question: string; answer: string }) => {
	logEvent('Answer Sleep Quiz Question', {
		question,
		answer,
		URL: formatLink(formatURL(window.location.href)),
	})
}

export const amCompleteSleepQuiz = () => {
	logEvent('Complete Sleep Quiz', {
		URL: formatLink(formatURL(window.location.href)),
	})
}
