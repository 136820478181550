import { ReactNode } from 'react'

const sessionFreeItemFlagName = 'freeItem'
// ACCESS20
export const accessoriesDiscountFactor = 0.2

export enum freeItemFlags {
	STORES_FREE_PILLOWS = 'showfieldsFreePillows',
	FREE_GRAVITY_BLANKET = 'freeGravityBlanket',
}

export const setFreeBlanketFlag = () => {
	sessionStorage.setItem(sessionFreeItemFlagName, freeItemFlags.FREE_GRAVITY_BLANKET)
}

export const setFreePillowFlag = () => {
	sessionStorage.setItem(sessionFreeItemFlagName, freeItemFlags.STORES_FREE_PILLOWS)
}

export const hasFreeBlanketFlag = () => {
	return sessionStorage.getItem(sessionFreeItemFlagName) === freeItemFlags.FREE_GRAVITY_BLANKET
}

/*
  Don't add pod preorder variant ids here because it will override the vanity codes for the pod if a user visits via vanity url.
  Unless you want to override the vanity codes for some reason.
*/

export const activeAutomaticDiscountCodes = ['SMARTBED100', 'FOUNDATION1', 'HEATWAVE', 'MEMBERACCESS30']

export const hiddenDiscountCodesMap = {
	MEMBERACCESS30: 'MEMBERSPECIAL',
	MEMBERACCESS10: 'MEMBERSPECIAL10',
}

export const headerABTestPromos = {
	financingBanner: {
		message: 'Financing Available from 0% APR.',
		link: '/financing',
		buttonText: 'Learn more',
	},
}

export const vanityHeaderPromos = {
	'vanity-test': {
		'*': {
			message: 'VANITY PROMO FOR THE WHOLE PAGE',
			link: null,
		},
		'/product/smart-bed': {
			message: 'SMART BED SPECIAL VANITY PROMO',
			link: null,
		},
	},
}

export const promoBlackList = ['/sleep-fitness-challenge', '/rewards', '/miami', '/miami/', '/hosts', '/hosts/', '/future/', '/future']

export const headerOfferMessage = null
export const headerOfferLink = null

export const holidaySalePodDiscount = 0

export interface SliderPromo {
	message: string | ReactNode
	link?: string
	showCountdown?: boolean
}

export const sliderPromos: SliderPromo[] = [
	{
		message: 'Get the Pod for as low as $73/mo for 24 months<sup>1</sup>.',
		showCountdown: true,
	},
	{
		message: 'Ready to ship. Ships within 7 business days.',
		showCountdown: true,
	},
]

export const LEX_DISCOUNT = 200
