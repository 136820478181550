import { setCookie } from 'config/utils'
import { Product } from 'products/types'

// https://dev.to/adrai/static-html-export-with-i18n-compatibility-in-nextjs-8cd
// https://github.com/Xairoo/nextjs-i18n-static-page-starter

export type RegionShort = '' | 'ca' | 'eu' | 'uk' | 'au' | 'se' | 'dk' | 'us' | 'ae' | 'ch' | 'mx'
export type RegionPageConfig = { [key: string]: RegionShort[] }
export type CountryMap = Record<RegionShort, string>

export const regions: RegionShort[] = ['', 'ca', 'eu', 'uk', 'au', 'se', 'dk', 'ae', 'ch', 'mx']

export const EU_REGIONS = ['eu', 'fi', 'se', 'dk', 'at', 'be', 'bg', 'cz', 'de', 'dk', 'ee', 'es', 'fr', 'hr', 'hu', 'ie', 'it', 'lt', 'lu', 'lv', 'mc', 'nl', 'pl', 'pt', 'ro', 'se', 'si', 'sk']
export interface CountryPickerProps {
	region: RegionShort
	setRegion: (region: RegionShort) => void
	onClick?: () => void
}

export interface IConfig {
	defaultRegion: RegionShort
	regions: RegionShort[]
	allRegions: RegionShort[]
}

export type BaseLocalePageProps = {
	locale?: string | string[]
}

export const config: IConfig = {
	defaultRegion: '',
	regions: ['ca', 'uk', 'eu', 'au', 'se', 'dk', 'ae', 'ch', 'mx'],
	allRegions: ['us', 'ca', 'uk', 'eu', 'au', 'se', 'dk', 'ae', 'ch', 'mx'],
}

// make sure the pages listed here are correctly set up in /pages/[locale] and /pageCores
export const availableRegionsForPaths: RegionPageConfig = {
	'/': [...config.regions],
	'/financing/': ['ca'],
	'/accessories/': ['ca'],
	'/member-accessories/': [...config.regions],
	'/pod-cover/': [...config.regions],
	'/product/pod-cover/': [...config.regions],
	'/product/shop-pod-cover/': [...config.regions],
	'/accessibility/': [...config.regions],
	'/return-policy/': [...config.regions],
	'/press/': [...config.regions],
	'/wall-of-love/': [...config.regions],
}

export const hreflangMap: Record<string, string[]> = {
	us: ['en-us'],
	ca: ['en-ca'],
	uk: ['en-gb'],
	au: ['en-au'],
	se: ['en-se'],
	dk: ['en-dk'],
	ae: ['en-ae'],
	ch: ['en-ch'],
	mx: ['es-mx'],
	eu: EU_REGIONS.slice(1).map((it) => `en-${it}`),
}

export const getFullPath = (path: string) => {
	if (typeof window === 'undefined') {
		return path
	}
	return window.location.pathname + (window.location.search ? '?' + window.location.search : '')
}

export const extractRegionFromPath = (path: string): RegionShort => {
	let region: RegionShort = ''
	config.allRegions.forEach((r) => {
		if (path === '/' + r || path.startsWith('/' + r + '/')) {
			region = r
		}
	})
	return region
}

export const regionIsEU = (region: RegionShort): boolean => {
	return EU_REGIONS.includes(region)
}

export const regionIsMetric = (region: RegionShort): boolean => {
	return region !== ''
}

const handleNonComRedirect = (region: RegionShort, pathname: string): boolean => {
	// Handle redirects between .com and country-specific TLDs
	if (typeof window !== 'undefined') {
		const currentHost = window.location.host
		const url = new URL(window.location.href)

		// If already on .com.mx domain, don't redirect when region is mx
		if (region === 'mx') {
			if (currentHost.endsWith('.com.mx')) {
				return false
			}

			if (currentHost === 'eightsleep.com') {
				url.host = 'eightsleep.com.mx'
				url.pathname = pathname
				window.location.href = url.toString()
				return true
			}

			return false
		}

		if (window.location.host.endsWith('.com.mx')) {
			// If on country-specific TLD (e.g. .com.mx or .jp), redirect to .com with region prefix
			url.host = 'eightsleep.com'
			url.pathname = `${region !== '' ? '/' + region : ''}${pathname}`
			window.location.href = url.toString()
			return true
		}

		return false
	}
	return false
}

export const redirectToRegion = (region: RegionShort, locale: string | null, pathname: string): void => {
	if (handleNonComRedirect(region, pathname)) {
		return
	}

	// const pathWithoutPrefix = getFullPath(router.asPath).replace(`/${extractRegionFromPath(getFullPath(router.asPath))}`, '')
	const pathWithoutPrefix = pathname.replace(`/${locale ?? extractRegionFromPath(pathname)}`, '')
	if (region === '') {
		setCookie('choseusa', 'true', 365)
	} else {
		setCookie('choseusa', 'false', 365)
	}

	window.location.href = `${region !== '' ? '/' + region : ''}${pathWithoutPrefix.startsWith('/') ? pathWithoutPrefix : '/' + pathWithoutPrefix}`
}

export const replaceMetricInProduct = (product: Product, region: RegionShort): Product => {
	const tagToExclude = regionIsMetric(region) ? 'nonMetric' : 'metric'
	const regex = new RegExp(`<${tagToExclude}>(.*?)</${tagToExclude}>`, 'g')
	//deletes the unwanted tag and contents, and then removes all tags
	product.description = product.description.map((desc) => desc.replace(regex, '').replace(/<(\/)?(non)?[M|m]etric>/g, ''))
	return product
}

/**
 * This is a helper function to get the locale from the params.
 * It returns an empty string (US) if the locale is not present.
 *
 * This _probably_ isn't needed post-migration, but it was needed to build during migration.
 * @param params
 * @returns locale region
 */
export const getSafeLocale = (params?: Record<string, string | string[]> | null): RegionShort => {
	if (!params) {
		return ''
	}

	const locale = (params?.locale ?? '') as RegionShort
	return locale
}
